<script setup lang="ts">
import { useAuthStore } from '@/stores/auth';

const { loggedIn, userRating, profile } = storeToRefs(useAuthStore());

const userFirstName = computed(() => {
  return profile.value?.firstName;
});

const userRatingRounded = computed(() => {
  if (userRating.value) {
    return Math.floor(userRating.value);
  }
});
</script>

<template>
  <div class="flex items-center gap-4">
    <NuxtLink v-if="loggedIn" to="/settings" class="flex items-center">
      <div class="hidden md:flex flex-col mx-2">
        <div
          class="grid grid-cols-[1fr_auto_auto] gap-1.5 text-white text-xl leading-[26px] font-medium text-end"
        >
          <p class="truncate max-w-80">{{ userFirstName }}</p>
          <span>•</span>
          <p>Level {{ userRatingRounded }}</p>
        </div>
        <span class="text-white text-sm leading-[18px] underline text-end">
          View Account
        </span>
      </div>
      <Avatar
        :image="profile?.avatarImage"
        :color="profile?.avatarColor"
        class-name="w-10 h-10 cursor-pointer"
      />
    </NuxtLink>
    <div v-else class="flex items-center">
      <NuxtLink to="/login" class="inline-flex items-center">
        <span class="hidden md:inline text-white text-xl font-medium mr-2">
          Log In
        </span>
        <NuxtIcon name="user" filled class="user-icon" />
      </NuxtLink>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.user-icon svg) {
  @apply w-10 h-10 mb-0;
}
</style>
